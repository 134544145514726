@import "antd/dist/reset.css";

body {
  --main-background-color: #e8fccf;
  --text-color: #134611;
  --link-text-color: #96e072;
  --link-background-color: #3e8914;
  --font-size: 36px;
  --footer-font-size: 16px
}

.quick-info {
  text-align: right;
}

.container {
  background: var(--main-background-color);
  min-height: 100vh;
  padding: 10px;

  font-family: "Ubuntu";
  font-weight: normal !important;
}

.ant-typography {
  color: var(--text-color) !important;
  margin-bottom: 0;
}

.link {
  font-size: var(--font-size);
  color: var(--link-text-color) !important;
  background-color: var(--link-background-color) !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size)
}

.bottom-icons {
  font-size: var(--footer-font-size);
  display: flex;
  flex-direction: column;
  align-items: center;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .bottom-text {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: var(--footer-font-size);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
